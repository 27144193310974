import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OptOutForm from "../components/optOutForm"
import OptOutWrapper from "../components/styles/OptOutStyles"

const IndexPage = ({ data, pageContext }) => {
  const { optOutSingleton } = data.swapi

  const form = {
    placeholderEmail: "Email",
    placeholderMessage: "Wpisz swoje żądanie dotyczące przetwarzania danych",
    emailError: "Niepoprawny email",
    messageError: "Wpisz swoje żądanie dotyczące przetwarzania danych",
    buttonText: "Wyślij",
    thx: "Dziękujemy! Nasz zespół skontaktuje się wkrótce.",
    formHeading: optOutSingleton.formHeading,
  }

  return (
    <Layout pageContext={pageContext}>
      <SEO title="Opt-Out" lang="pl" />
      <div className="container">
        <OptOutWrapper>
          <h1 className="title">Opt-Out</h1>
          <p
            className="subtitle"
            dangerouslySetInnerHTML={{ __html: form.formHeading }}
          />
          {/* <OptOutForm form={form} /> */}
        </OptOutWrapper>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query optOutPagePL {
    swapi {
      optOutSingleton(lang: "pl") {
        formHeading
      }
    }
  }
`
